#work_div {
    width: 40vw;
}

#work_items {
    margin: 0 !important;
    padding: 0 !important;
}

.work_item {
    margin: 0 15px !important;
    padding: 0 !important;
}

#work_items ul {
    list-style-type: disc;
}

@media all and (max-width: 600px) {
    #work_div {
        width: 75vw;
    }
}
