#intro_main_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#intro_div {
    margin: 0 !important;
}

#intro_name {
    color: #ededed;
    margin: 15px 0;
}

#intro_i_do {
    color: #a3a3a3;
    margin: 5px 0;
}

#intro_intro {
    width: 30vw;
    margin: 35px 0 !important;
}

@keyframes pulse_dot {
    0%, 100% {
        color: #ff0000;
    }

    50% {
        color: #ffffff;
    }
}

#intro_circle {
    color: #ff0000;
    animation: pulse_dot;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
    margin-top: 20px;
}

@media all and (max-width: 600px) {
    #intro_intro {
        width: 70vw;
    }

    #intro_main_div {
        flex-direction: column;
    }

    #open_to_work {
        display: block;
        margin-top: 30px !important;
    }

    #intro_custom_label {
        font-weight: bolder;
    }
}
