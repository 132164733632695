#contact_div {
    width: 40vw;
}

#contact_div div {
    margin: 0 !important;
    padding: 0 !important;
}

#form_div {
    border: 1px solid #101f36;
    background-color: #101f36;
    padding: 20px;
    border-radius: 10px;
}

@media all and (max-width: 600px) {
    #contact_div {
        width: 75vw;
    }
}
