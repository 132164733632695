body {
    overflow: overlay;
}

#main_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #172945;
    padding: 15px 40px;
    color: #ffffff;
    scroll-behavior: smooth;
}

#mid_div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    scroll-behavior: smooth;
}

#social_div {
    order: 1;
    display: flex;
    flex-direction: column;
    width: 75px;
    text-align: center;
    position: fixed;
    align-self: flex-start;
    left: 0;
    margin: 18vh 0 0 25px;
}

#social_div a {
    padding: 0;
    margin: 8px;
    font-size: 1.4rem;
    transition: all .2s;
}

#social_div a:hover {
    font-size: 1.8rem;
}

#data_div {
    order: 2;
    scroll-behavior: smooth;
    overflow-y: scroll;
    position: relative;
    -ms-overflow-style: none;
    margin-left: 20vw;
}

#data_div::-webkit-scrollbar {
    width: 0;
    display: none;
}

::-webkit-scrollbar {
    width: 20px;
    background-color: rgba(0, 0, 0, 0.0);
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(168, 199, 247, 0.2);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(48, 80, 130, 0.4);
}


#data_div div:nth-of-type(1) {
    margin: 25vh 0 40vh 0;
}

#data_div div:nth-of-type(5) {
    margin: 40vh 0 0 0;
}

#contact {
    margin-bottom: -25vh !important;
}

#data_div div {
    margin: 0;
    transition: all .3s;
}

.navbar {
    background-color: rgba(23, 41, 69, 0.7) !important;
    backdrop-filter: blur(6px) !important;
    -webkit-backdrop-filter: blur(8px) !important;
}

#navbarNav {
    min-height: 75px;
    float: right;
    right: 0;
    top: 0;
    position: absolute;
}

#navbarNav ul li {
    margin: 5px 15px;
}

#navbarNav ul li a {
    margin-bottom: 20px;
}

.custom_outline_button {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #75afff;
    padding: 8px 16px;
    border-radius: 6px;
    color: #75afff;
    transition: all .2s;
}

.custom_outline_button:hover {
    background-color: rgba(117, 175, 255, 0.2);
    color: #8de1f0;
    border-color: #8de1f0;
}

footer {
    margin: 8vh 0 5vh 0;
    text-align: center;
}

a {
    transition: all .2s;
    text-decoration: none;
    color: #75afff;
}

@media all and (max-width: 1400px) {
    #data_div div:nth-of-type(1) {
        margin: 12vh 0 40vh 0;
    }

    #data_div div:nth-of-type(5) {
        margin: 40vh 0 0 0;
    }
}

@media all and (max-width: 600px) {
    #main_div {
        padding: 10px 15px;
    }

    #navbarNav {
        position: initial;
    }

    #mid_div {
        flex-direction: column;
        align-items: center;
    }

    #data_div {
        order: 1;
        margin-left: initial;
    }

    #social_div {
        order: 2;
        margin-top: 60px;
        flex-direction: row;
        margin-right: 125px;
        position: initial;
        align-self: center;
        left: initial;
    }
}
