#skills_div {
    width: 40vw;
}

#skills_list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 !important;
    padding: 0 !important;
}

#skills_p1, #skills_p2 {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1.2rem;
}

#skills_p1 code ul li, #skills_p2 code ul li {
    margin: 15px 0;
}

@media all and (max-width: 600px) {
    #skills_div {
        width: 80vw;
    }
}
