.main-container {
    margin: 0;
    padding: 0;
    background: #2d2d2d;
    color: white;
    font-family: Consolas, 'Courier New', monospace;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    scroll-behavior: smooth;
}

.terminal {
    width: 90%;
    max-width: 1000px;
    height: 80%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;
    scroll-behavior: smooth;
}

.terminal-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 10px;
    background-color: #2b2b2b;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.macos-buttons {
    display: flex;
    gap: 8px;
}

.macos-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: all .1s;
}

.macos-button.close {
    background-color: #ff5f57;
}

.macos-button.close:hover {
    background-color: #fc372d;
}

.macos-button.minimize {
    background-color: #ffbd2e;
}

.macos-button.minimize:hover {
    background-color: #e8a71a;
}

.macos-button.maximize {
    background-color: #27c93f;
}

.macos-button.maximize:hover {
    background-color: #1fa132;
}

.terminal-body {
    overflow-y: auto;
    max-height: 100%;
    padding: 0 20px 0 20px;
    scroll-behavior: smooth;
}

.input-line {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 15px 15px 15px;
}

.input-line input {
    align-self: flex-start;
}

a, .fake-link {
    text-decoration: underline;
    color: #75afff;
    cursor: pointer;
    transition: all .1s;
}

a:hover, .fake-link:hover {
    color: #99c4ff;
}

.prompt {
    margin-right: 8px;
    line-height: 1.5;
}

#footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 25px;
}

input {
    border: none;
    background: none;
    color: white;
    font-family: inherit;
    font-size: 16px;
    outline: none;
    flex: 1;
    line-height: 1.5;
    padding: 0;
}

::-webkit-scrollbar {
    width: 20px;
    background-color: rgba(0, 0, 0, 0.0);
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(168, 199, 247, 0.2);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    transition: all .2s !important;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(220, 220, 220, 0.4);
}

.caret {
    display: inline-block;
    background: lime;
    width: 8px;
    height: 18px;
    vertical-align: bottom;
    animation: blink 1s steps(2, start) infinite;
}


@media (max-width: 600px) {
    .terminal {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .terminal-header {
        display: none;
    }

    .terminal-body {
        padding: 12px;
    }
}

@keyframes blink {
    50% {
        background: transparent;
    }
}