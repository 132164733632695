#about_me {
    width: 40vw;
}

#about_me_paragraph {
    width: 35vw;
}

@media all and (max-width: 600px) {
    #about_me_paragraph {
        width: 75vw;
    }
}
